<template>
  <div class="page">
    <sideBarTwo></sideBarTwo>
    <div class="vip_back">
      <div class="adve">
        <div class="login">
          <div class="validity" v-if="!user" @click="goPage('login')">登录</div>
          <div class="info" v-if="user">
            <div class="vipimg">
              <img class="ava" :src="user.MemberPersonalPic?user.MemberPersonalPic:require('../assets/icon_head40.png')"
                alt="">
              <img v-if="user.AssociatorState=='已开通'" class="vip" src="../assets/icon_vip24.png" alt="">
            </div>
            <span>{{user.MemberName}}</span>
          </div>
          <div class="noopen" v-if="user&&user.AssociatorState=='未开通'">暂未开通</div>
          <div class="validity" v-if="user&&user.AssociatorState=='已开通'">会员有效期：{{user.AssociatorTimeEnd}}</div>
          <div class="validity" v-if="user&&user.AssociatorState=='已过期'">会员有效期：已过期</div>
        </div>
        <div class="title">律兜法律咨询VIP</div>
        <div class="des"><span>·律兜会员</span><span>·特惠上线</span><span>·尊享特权</span></div>
        <div class="btns">
          <span
            @click="join(!user||(user&&user.AssociatorState=='未开通')?1:2)">{{!user||(user&&user.AssociatorState!='已开通')?'立即加入律兜VIP':'立即续费'}}</span>
          <div class="only" v-if="!user||(user&&user.AssociatorState=='未开通')">首月仅38元</div>
        </div>
        <div class="why">
          <img src="../assets/img_vs173.png" alt="">
        </div>
      </div>
    </div>
    <div class="types">
      <img @click="join(1)" src="../assets/img_vip_sale.png" alt="" v-if="!user||(user&&user.AssociatorState=='未开通')">
      <img @click="join(2)" src="../assets/img_vip_month.png" alt="">
      <img @click="join(3)" src="../assets/img_vip_quarter.png" alt="">
      <img @click="join(4)" src="../assets/img_vip_year.png" alt="">
    </div>
    <div class="tips_box">
      <p>温馨提示</p>
      <div>1、律兜会员权益：每月可享3张价值38元的图文咨询优惠券，1张价值38元的电话咨询优惠券，1张价值98元的私人律师优惠券，海量合同模板免费下载；</div>
      <div>2、开通会员后，当月会员优惠券立即到账，之后按会员周期发放优惠券；</div>
      <div>3、退款规则：若您未使用任何会员权益，可申请全额退款；若已使用会员权益，不支持退款。</div>
    </div>
    <div class="more">
      更多权益 敬请期待
    </div>
    <FootBox></FootBox>
  </div>
</template>

<script>
  import axios from 'axios'
  import sideBarTwo from '@/components/sideBarTwo'
  import FootBox from '@/components/FootBox'
  import {
    GetMemberAssociatorPCList,
    QuickAssociatorOrder
  } from '@/api'
  export default {
    data() {
      return {
        user: null,
        member1: {},
        member2: {},
        member3: {},
        member4: {}
      }
    },
    mounted() {
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      })
      if (this.user != null) {
        GetMemberAssociatorPCList({
          "memberGuid": this.user.MemberGuid
        }).then(res => {
          if (res.state == 0) {
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].ProductTitle == '1个月会员') {
                this.member1 = res.data[i]
              } else if (res.data[i].ProductTitle == '3个月会员') {
                this.member2 = res.data[i]
              } else if (res.data[i].ProductTitle == '1年会员') {
                this.member3 = res.data[i]
              } else {
                this.member4 = res.data[i]
              }
            }
          }
        })
      }
      _hmt.push(['_trackPageview', '/member'])
    },
    methods: {
      goPage(path) {
        this.$router.push('/' + path)
      },
      join(num) {

        if (!localStorage.getItem('user')) {
          this.$router.push('/login')
        } else {
          var ProductGuid = ''
          switch (num) {
            case 1:
              ProductGuid = this.member4.ProductGuid
              _hmt.push(['_trackEvent', 'click', '立即开通', this.member1.ProductTitle]);
              break;
            case 2:
              ProductGuid = this.member1.ProductGuid
              _hmt.push(['_trackEvent', 'click', '立即开通', this.member1.ProductTitle]);
              break;
            case 3:
              ProductGuid = this.member2.ProductGuid
              _hmt.push(['_trackEvent', 'click', '立即开通', this.member2.ProductTitle]);
              break;
            case 4:
              ProductGuid = this.member3.ProductGuid
              _hmt.push(['_trackEvent', 'click', '立即开通', this.member3.ProductTitle]);
              break;
          }
          var data = {
            "productGuid": ProductGuid,
            "memberGuid": this.user.MemberGuid,
            "orderFrom": localStorage.getItem('orderfrom'),
          }
          console.log(data)
          QuickAssociatorOrder(data).then(res => {
            if (res.state == 0) {
              localStorage.setItem('payOrder', JSON.stringify(res.data))
              if (num == 1) {
                this.$router.push('/cashier?yhMember=1')
              } else {
                this.$router.push('/cashier')
              }
            } else {
              this.$message(res.des)
            }
          })
        }
      }
    },
    components: {
      sideBarTwo,
      FootBox
    }
  }

</script>
<style scoped lang="scss">
  .vip_back {
    height: 470px;
    background: #131416;

    .adve {
      position: relative;
      margin: auto;
      width: 940px;
      height: 100%;
      background-image: url('../assets/img_vip_bg.png');
      background-size: 100%;
      background-repeat: no-repeat;

      .login {
        position: absolute;
        top: 20px;
        right: 13px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: default;

        .info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          margin-bottom: 5px;

          .vipimg {
            margin-right: 5px;
            position: relative;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 35px;

            .ava {
              margin-right: 8px;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
            }

            .vip {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 12px;
              height: 11px;
            }
          }

          span {
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .noopen {
          font-size: 14px;
          color: #AAAAAA;
          text-align: right;
        }

        .validity {
          font-size: 14px;
          color: #FFFFFF;
          text-align: right;
        }
      }

      .title {
        padding-top: 56px;
        font-size: 36px;
        font-weight: 500;
        color: #FFFEFE;
        text-align: center;
      }

      .des {
        display: flex;
        justify-content: space-between;
        width: 277px;
        margin: 13px auto 50px;
        font-size: 18px;
        color: #fff;
      }
    }
  }

  .btns {
    margin: auto;
    position: relative;
    width: 200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #53300C;
    border-radius: 20px;
    background: linear-gradient(-8deg, #E2AB75 0%, #FFD8A2 100%);
    cursor: default;

    .only {
      position: absolute;
      right: -35px;
      top: -13px;
      width: 75px;
      height: 20px;
      line-height: 20px;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 12px;
      background: #F24537;
      border-radius: 10px 0px 10px 0px;
    }
  }

  .why {
    margin-top: 40px;
    height: 173px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .types {
    display: flex;
    justify-content: center;
    height: 590px;
    background: #485065;

    img {
      margin: 45px 10px;
      width: 220px;
      height: 500px;
    }
  }

  .tips_box {
    padding-top: 30px;
    height: 240px;
    background: #2F3240;
    color: #fff;
    box-sizing: border-box;

    p {
      margin-bottom: 28px;
      text-align: center;
      font-size: 24px;
    }

    >div {
      margin: 0 auto 10px;
      width: 940px;
      font-size: 16px;
    }
  }

  .more {
    height: 96px;
    line-height: 96px;
    text-align: center;
    font-size: 16px;
    color: #AAAAAA;
  }

</style>
